/* Skeleton */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #ffffff;
}

#route_content {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

/* Hover Effects */
.hover-darken:hover {
  filter: brightness(80%);
}

/* Fonts */
@font-face {
  font-family: 'Roboto-Black';
  src: url('/public/fonts/Roboto/Roboto-Black.ttf');
}
@font-face {
  font-family: 'Roboto-Black-Italic';
  src: url('/public/fonts/Roboto/Roboto-BlackItalic.ttf');
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('/public/fonts/Roboto/Roboto-Bold.ttf');
}
@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('/public/fonts/Roboto/Roboto-BoldItalic.ttf');
}
@font-face {
  font-family: 'Roboto-Italic';
  src: url('/public/fonts/Roboto/Roboto-Italic.ttf');
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('/public/fonts/Roboto/Roboto-Light.ttf');
}
@font-face {
  font-family: 'Roboto-Light-Italic';
  src: url('/public/fonts/Roboto/Roboto-LightItalic.ttf');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('/public/fonts/Roboto/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto-Medium-Italic';
  src: url('/public/fonts/Roboto/Roboto-MediumItalic.ttf');
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('/public/fonts/Roboto/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto-Thin';
  src: url('/public/fonts/Roboto/Roboto-Thin.ttf');
}
@font-face {
  font-family: 'Roboto-Thin-Italic';
  src: url('/public/fonts/Roboto/Roboto-ThinItalic.ttf');
}
