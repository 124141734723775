.top_right-enter {
  opacity: 0;
  transform: scale(0);
  transform-origin: top right;
}

.top_right-enter-active {
  opacity: 1;
  transform: scale(1);
  transform-origin: top right;
  transition: transform 300ms, opacity 300ms;
}

.top_right-exit {
  opacity: 1;
  transform-origin: top right;
}

.top_right-exit-active {
  opacity: 0;
  transform: scale(0);
  transform-origin: top right;
  transition: transform 300ms, opacity 300ms;
}
