/* Animated Underlining */
.blue_underline:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0px;
  background-color: #3f70ff;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.blue_underline:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Static Styles */
.static_blue_underline:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Hover Effects */
.blue_hover:hover {
  color: #3f70ff !important;
}
